import "whatwg-fetch";
import React, { useState } from "react";
import onClickOutside from "react-onclickoutside";
import AutocompleteTitleItem from "./AutocompleteTitleItem";
import Helpers from "./helpers/helpers";

const AutocompleteTitle = ({ data, triggerSubmit, text, setData }) => {
  const [value, setValue] = useState();
  const [events, setEvents] = useState();
  const [showResults, toggleResults] = useState(true);

  const handleChange = (e) => {
    var val = e.target.value;
    if (val === "") {
      setValue(val);
    } else {
      setValue(val);
      setData({ ...data, title: val, submit: true });
      callApiToAutocomplete(val);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      triggerSubmit();
    } else if (e.key === "Escape") {
      toggleResults(false);
    }
  };

  const handleItemClick = (e) => {
    const val = e.target.innerHTML;
    setValue(val);
    setData({ ...data, title: val, submit: true });
    triggerSubmit(true);
  };

  function buildAutocompleteUrl(value) {
    const additionalFilters = addFilters(data);
    let url = `/social_events/autocomplete_title?q[title]=${value}${additionalFilters}`;
    return url;
  }

  function addFilters(data) {
    let params = Helpers.buildUrl(data);
    return params;
  }

  function callApiToAutocomplete(value) {
    fetch(buildAutocompleteUrl(value), {
      method: "GET",
    }).then(handleResponse);
  }

  const handleResponse = (res) => {
    if (res.ok) {
      res.json().then(handleData);
    }
  };

  const handleData = (res) => {
    setEvents(res);
    toggleResults(true);
  };

  return (
    <div className="autocomplete-wrap position-relative flex-grow-1">
      <div
        className="d-flex flex-row"
        style={{
          boxShadow: "1px 10px 10px rgba(150, 170, 195, 0.1)",
          borderRadius: 40,
        }}
      >
        <input
          type="text"
          name="q[title]"
          autoComplete="off"
          className="form-control py-3 ps-4 fs-4"
          style={{
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
            borderRightWidth: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
          placeholder={text.title_placeholder}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
        />
        <div
          className="d-flex align-items-center justify-content-center px-3 border bg-white"
          style={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
            borderLeftWidth: 0,
            backgroundColor: data.submit ? "#ffa301" : "#212529",
          }}
          onClick={() => triggerSubmit(true)}
        >
          <img src={"/assets/iconPark/search.svg"} width={30} height={30} />
        </div>
      </div>
      <ul className="autocomplete-menu autocomplete-menu-search w-100 rounded bg-white">
        {showResults &&
          events &&
          events.map((item, idx) => (
            <AutocompleteTitleItem key={idx} index={idx} name={item} handleItemClick={handleItemClick} />
          ))}
      </ul>
    </div>
  );
};

export default AutocompleteTitle;
