export const COUNTRIES = [
  {
    title: "Afghanistan",
    value: "AF",
  },
  {
    title: "Albania",
    value: "AL",
  },
  {
    title: "Algeria",
    value: "DZ",
  },
  {
    title: "American Samoa",
    value: "AS",
  },
  {
    title: "Andorra",
    value: "AD",
  },
  {
    title: "Angola",
    value: "AO",
  },
  {
    title: "Anguilla",
    value: "AI",
  },
  {
    title: "Argentina",
    value: "AR",
  },
  {
    title: "Armenia",
    value: "AM",
  },
  {
    title: "Aruba",
    value: "AW",
  },
  {
    title: "Australia",
    value: "AU",
  },
  {
    title: "Austria",
    value: "AT",
  },
  {
    title: "Azerbaijan",
    value: "AZ",
  },
  {
    title: "Bahamas",
    value: "BS",
  },
  {
    title: "Bahrain",
    value: "BH",
  },
  {
    title: "Bangladesh",
    value: "BD",
  },
  {
    title: "Barbados",
    value: "BB",
  },
  {
    title: "Belarus",
    value: "BY",
  },
  {
    title: "Belgium",
    value: "BE",
  },
  {
    title: "Belize",
    value: "BZ",
  },
  {
    title: "Benin",
    value: "BJ",
  },
  {
    title: "Bermuda",
    value: "BM",
  },
  {
    title: "Bhutan",
    value: "BT",
  },
  {
    title: "Bolivia",
    value: "BO",
  },
  {
    title: "Bosnia and Herzegovina",
    value: "BA",
  },
  {
    title: "Botswana",
    value: "BW",
  },
  {
    title: "Brazil",
    value: "BR",
  },
  {
    title: "British Virgin Islands",
    value: "VG",
  },
  {
    title: "Brunei",
    value: "BN",
  },
  {
    title: "Bulgaria",
    value: "BG",
  },
  {
    title: "Burkina Faso",
    value: "BF",
  },
  {
    title: "Burundi",
    value: "BI",
  },
  {
    title: "Cambodia",
    value: "KH",
  },
  {
    title: "Cameroon",
    value: "CM",
  },
  {
    title: "Canada",
    value: "CA",
  },
  {
    title: "Cape Verde",
    value: "CV",
  },
  {
    title: "Cayman Islands",
    value: "KY",
  },
  {
    title: "Central African Republic",
    value: "CF",
  },
  {
    title: "Chad",
    value: "TD",
  },
  {
    title: "Chile",
    value: "CL",
  },
  {
    title: "China",
    value: "CN",
  },
  {
    title: "Columbia",
    value: "CO",
  },
  {
    title: "Comoros",
    value: "KM",
  },
  {
    title: "Cook Islands",
    value: "CK",
  },
  {
    title: "Costa Rica",
    value: "CR",
  },
  {
    title: "Croatia",
    value: "HR",
  },
  {
    title: "Cuba",
    value: "CU",
  },
  {
    title: "Curacao",
    value: "CW",
  },
  {
    title: "Cyprus",
    value: "CY",
  },
  {
    title: "Czech Republic",
    value: "CZ",
  },
  {
    title: "Democratic Republic of the Congo",
    value: "CD",
  },
  {
    title: "Denmark",
    value: "DK",
  },
  {
    title: "Djibouti",
    value: "DJ",
  },
  {
    title: "Dominica",
    value: "DM",
  },
  {
    title: "Dominican Republic",
    value: "DO",
  },
  {
    title: "East Timor",
    value: "TL",
  },
  {
    title: "Ecuador",
    value: "EC",
  },
  {
    title: "Egypt",
    value: "EG",
  },
  {
    title: "El Salvador",
    value: "SV",
  },
  {
    title: "Eritrea",
    value: "ER",
  },
  {
    title: "Estonia",
    value: "EE",
  },
  {
    title: "Ethiopia",
    value: "ET",
  },
  {
    title: "Faroe Islands",
    value: "FO",
  },
  {
    title: "Fiji",
    value: "FJ",
  },
  {
    title: "Finland",
    value: "FI",
  },
  {
    title: "France",
    value: "FR",
  },
  {
    title: "French Polynesia",
    value: "PF",
  },
  {
    title: "Gabon",
    value: "GA",
  },
  {
    title: "Gambia",
    value: "GM",
  },
  {
    title: "Georgia",
    value: "GE",
  },
  {
    title: "Germany",
    value: "DE",
  },
  {
    title: "Ghana",
    value: "GH",
  },
  {
    title: "Greece",
    value: "GR",
  },
  {
    title: "Greenland",
    value: "GL",
  },
  {
    title: "Grenada",
    value: "GD",
  },
  {
    title: "Guam",
    value: "GU",
  },
  {
    title: "Guatemala",
    value: "GT",
  },
  {
    title: "Guernsey",
    value: "GG",
  },
  {
    title: "Guinea",
    value: "GN",
  },
  {
    title: "Guinea-Bissau",
    value: "GW",
  },
  {
    title: "Guyana",
    value: "GY",
  },
  {
    title: "Haiti",
    value: "HT",
  },
  {
    title: "Honduras",
    value: "HN",
  },
  {
    title: "Hong Kong",
    value: "HK",
  },
  {
    title: "Hungary",
    value: "HU",
  },
  {
    title: "Iceland",
    value: "IS",
  },
  {
    title: "India",
    value: "IN",
  },
  {
    title: "Indonesia",
    value: "ID",
  },
  {
    title: "Iran",
    value: "IR",
  },
  {
    title: "Iraq",
    value: "IQ",
  },
  {
    title: "Ireland",
    value: "IE",
  },
  {
    title: "Isle of Man",
    value: "IM",
  },
  {
    title: "Israel",
    value: "IL",
  },
  {
    title: "Italy",
    value: "IT",
  },
  {
    title: "Ivory Coast",
    value: "CI",
  },
  {
    title: "Jamaica",
    value: "JM",
  },
  {
    title: "Japan",
    value: "JP",
  },
  {
    title: "Jersey",
    value: "JE",
  },
  {
    title: "Jordan",
    value: "JO",
  },
  {
    title: "Kazakhstan",
    value: "KZ",
  },
  {
    title: "Kenya",
    value: "KE",
  },
  {
    title: "Kiribati",
    value: "KI",
  },
  {
    title: "Kosovo",
    value: "XK",
  },
  {
    title: "Kuwait",
    value: "KW",
  },
  {
    title: "Kyrgyzstan",
    value: "KG",
  },
  {
    title: "Laos",
    value: "LA",
  },
  {
    title: "Latvia",
    value: "LV",
  },
  {
    title: "Lebanon",
    value: "LB",
  },
  {
    title: "Lesotho",
    value: "LS",
  },
  {
    title: "Liberia",
    value: "LB",
  },
  {
    title: "Libya",
    value: "LY",
  },
  {
    title: "Liechtenstein",
    value: "LI",
  },
  {
    title: "Lithuania",
    value: "LT",
  },
  {
    title: "Luxembourg",
    value: "LU",
  },
  {
    title: "Macau",
    value: "MO",
  },
  {
    title: "Macedonia",
    value: "MK",
  },
  {
    title: "Madagascar",
    value: "MG",
  },
  {
    title: "Malawi",
    value: "MW",
  },
  {
    title: "Malaysia",
    value: "MY",
  },
  {
    title: "Maldives",
    value: "MV",
  },
  {
    title: "Mali",
    value: "ML",
  },
  {
    title: "Malta",
    value: "MT",
  },
  {
    title: "Marshall Islands",
    value: "MH",
  },
  {
    title: "Mauritania",
    value: "MR",
  },
  {
    title: "Mauritius",
    value: "MU",
  },
  {
    title: "Mayotte",
    value: "YT",
  },
  {
    title: "Mexico",
    value: "MX",
  },
  {
    title: "Micronesia",
    value: "FM",
  },
  {
    title: "Moldova",
    value: "MD",
  },
  {
    title: "Monaco",
    value: "MC",
  },
  {
    title: "Mongolia",
    value: "MN",
  },
  {
    title: "Montenegro",
    value: "ME",
  },
  {
    title: "Morocco",
    value: "MA",
  },
  {
    title: "Mozambique",
    value: "MZ",
  },
  {
    title: "Myanmar",
    value: "MM",
  },
  {
    title: "Namibia",
    value: "NA",
  },
  {
    title: "Nepal",
    value: "NP",
  },
  {
    title: "Netherlands",
    value: "NL",
  },
  {
    title: "Netherlands Antilles",
    value: "AN",
  },
  {
    title: "New Caledonia",
    value: "NC",
  },
  {
    title: "New Zealand",
    value: "NZ",
  },
  {
    title: "Nicaragua",
    value: "NI",
  },
  {
    title: "Niger",
    value: "NE",
  },
  {
    title: "Nigeria",
    value: "NG",
  },
  {
    title: "North Korea",
    value: "KP",
  },
  {
    title: "Northern Mariana Islands",
    value: "MP",
  },
  {
    title: "Norway",
    value: "NO",
  },
  {
    title: "Oman",
    value: "OM",
  },
  {
    title: "Pakistan",
    value: "PK",
  },
  {
    title: "Palestine",
    value: "PS",
  },
  {
    title: "Panama",
    value: "PA",
  },
  {
    title: "Papua New Guinea",
    value: "PG",
  },
  {
    title: "Paraguay",
    value: "PY",
  },
  {
    title: "Peru",
    value: "PE",
  },
  {
    title: "Philippines",
    value: "PH",
  },
  {
    title: "Poland",
    value: "PL",
  },
  {
    title: "Portugal",
    value: "PT",
  },
  {
    title: "Puerto Rico",
    value: "PR",
  },
  {
    title: "Qatar",
    value: "QA",
  },
  {
    title: "Republic of the Congo",
    value: "CG",
  },
  {
    title: "Reunion",
    value: "RE",
  },
  {
    title: "Romania",
    value: "RO",
  },
  {
    title: "Russia",
    value: "RU",
  },
  {
    title: "Rwanda",
    value: "RW",
  },
  {
    title: "Saint Kitts and Nevis",
    value: "KN",
  },
  {
    title: "Saint Lucia",
    value: "LC",
  },
  {
    title: "Saint Martin",
    value: "MF",
  },
  {
    title: "Saint Pierre and Miquelon",
    value: "PM",
  },
  {
    title: "Saint Vincent and the Grenadines",
    value: "VC",
  },
  {
    title: "Samoa",
    value: "WS",
  },
  {
    title: "San Marino",
    value: "SM",
  },
  {
    title: "Sao Tome and Principe",
    value: "ST",
  },
  {
    title: "Saudi Arabia",
    value: "SA",
  },
  {
    title: "Senegal",
    value: "SN",
  },
  {
    title: "Serbia",
    value: "RS",
  },
  {
    title: "Seychelles",
    value: "SC",
  },
  {
    title: "Sierra Leone",
    value: "SL",
  },
  {
    title: "Singapore",
    value: "SG",
  },
  {
    title: "Sint Maarten",
    value: "SX",
  },
  {
    title: "Slovakia",
    value: "SK",
  },
  {
    title: "Slovenia",
    value: "SI",
  },
  {
    title: "Solomon Islands",
    value: "SB",
  },
  {
    title: "Somalia",
    value: "SO",
  },
  {
    title: "South Africa",
    value: "ZA",
  },
  {
    title: "South Korea",
    value: "KR",
  },
  {
    title: "South Sudan",
    value: "SS",
  },
  {
    title: "Spain",
    value: "ES",
  },
  {
    title: "Sri Lanka",
    value: "LK",
  },
  {
    title: "Sudan",
    value: "SD",
  },
  {
    title: "Suriname",
    value: "SR",
  },
  {
    title: "Swaziland",
    value: "SZ",
  },
  {
    title: "Sweden",
    value: "SE",
  },
  {
    title: "Switzerland",
    value: "CH",
  },
  {
    title: "Syria",
    value: "SY",
  },
  {
    title: "Taiwan",
    value: "TW",
  },
  {
    title: "Tajikistan",
    value: "TJ",
  },
  {
    title: "Tanzania",
    value: "TZ",
  },
  {
    title: "Thailand",
    value: "TH",
  },
  {
    title: "Togo",
    value: "TG",
  },
  {
    title: "Tonga",
    value: "TO",
  },
  {
    title: "Trinidad and Tobago",
    value: "TT",
  },
  {
    title: "Tunisia",
    value: "TN",
  },
  {
    title: "Turkey",
    value: "TR",
  },
  {
    title: "Turkmenistan",
    value: "TM",
  },
  {
    title: "Turks and Caicos Islands",
    value: "TC",
  },
  {
    title: "Tuvalu",
    value: "TV",
  },
  {
    title: "U.S. Virgin Islands",
    value: "VI",
  },
  {
    title: "Uganda",
    value: "UG",
  },
  {
    title: "Ukraine",
    value: "UA",
  },
  {
    title: "United Arab Emirates",
    value: "AE",
  },
  {
    title: "United Kingdom",
    value: "GB",
  },
  {
    title: "United States",
    value: "US",
  },
  {
    title: "Uruguay",
    value: "UY",
  },
  {
    title: "Uzbekistan",
    value: "UZ",
  },
  {
    title: "Vanuatu",
    value: "VU",
  },
  {
    title: "Venezuela",
    value: "VE",
  },
  {
    title: "Vietnam",
    value: "VN",
  },
  {
    title: "Western Sahara",
    value: "EH",
  },
  {
    title: "Yemen",
    value: "YE",
  },
  {
    title: "Zambia",
    value: "ZM",
  },
  {
    title: "Zimbabwe",
    value: "ZW",
  },
];

export const translatedCountries = COUNTRIES.map((country) => ({
  en: country.title,
  es: translateToSpanish(country.value),
  de: translateToGerman(country.value),
  fr: translateToFrench(country.value),
  it: translateToItalian(country.value),
  pt: translateToPortuguese(country.value),
  value: country.value,
}));

function translateToGerman(value) {
  const translations = {
    AF: "Afghanistan",
    AL: "Albanien",
    DZ: "Algerien",
    AS: "Amerikanisch-Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AR: "Argentinien",
    AM: "Armenien",
    AW: "Aruba",
    AU: "Australien",
    AT: "Österreich",
    AZ: "Aserbaidschan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesch",
    BB: "Barbados",
    BY: "Weißrussland",
    BE: "Belgien",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivien",
    BA: "Bosnien und Herzegowina",
    BW: "Botswana",
    BR: "Brasilien",
    VG: "Britische Jungferninseln",
    BN: "Brunei",
    BG: "Bulgarien",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Kambodscha",
    CM: "Kamerun",
    CA: "Kanada",
    CV: "Kap Verde",
    KY: "Kaimaninseln",
    CF: "Zentralafrikanische Republik",
    TD: "Tschad",
    CL: "Chile",
    CN: "China",
    CO: "Kolumbien",
    KM: "Komoren",
    CK: "Cookinseln",
    CR: "Costa Rica",
    HR: "Kroatien",
    CU: "Kuba",
    CW: "Curaçao",
    CY: "Zypern",
    CZ: "Tschechische Republik",
    CD: "Demokratische Republik Kongo",
    DK: "Dänemark",
    DJ: "Dschibuti",
    DM: "Dominica",
    DO: "Dominikanische Republik",
    EC: "Ecuador",
    EG: "Ägypten",
    SV: "El Salvador",
    ER: "Eritrea",
    EE: "Estland",
    ET: "Äthiopien",
    FO: "Färöer-Inseln",
    FJ: "Fidschi",
    FI: "Finnland",
    FR: "Frankreich",
    PF: "Französisch-Polynesien",
    GA: "Gabun",
    GM: "Gambia",
    GE: "Georgien",
    DE: "Deutschland",
    GH: "Ghana",
    GR: "Griechenland",
    GL: "Grönland",
    GD: "Grenada",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HN: "Honduras",
    HK: "Hongkong",
    HU: "Ungarn",
    IS: "Island",
    IN: "Indien",
    ID: "Indonesien",
    IR: "Iran",
    IQ: "Irak",
    IE: "Irland",
    IM: "Isle of Man",
    IL: "Israel",
    IT: "Italien",
    CI: "Elfenbeinküste",
    JM: "Jamaika",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordanien",
    KZ: "Kasachstan",
    KE: "Kenia",
    KI: "Kiribati",
    XK: "Kosovo",
    KW: "Kuwait",
    KG: "Kirgisistan",
    LA: "Laos",
    LV: "Lettland",
    LB: "Libanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libyen",
    LI: "Liechtenstein",
    LT: "Litauen",
    LU: "Luxemburg",
    MO: "Macao",
    MK: "Nordmazedonien",
    MG: "Madagaskar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Malediven",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshallinseln",
    MR: "Mauretanien",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexiko",
    FM: "Mikronesien",
    MD: "Moldawien",
    MC: "Monaco",
    MN: "Mongolei",
    ME: "Montenegro",
    MA: "Marokko",
    MZ: "Mosambik",
    MM: "Myanmar",
    NA: "Namibia",
    NP: "Nepal",
    NL: "Niederlande",
    AN: "Niederländische Antillen",
    NC: "Neukaledonien",
    NZ: "Neuseeland",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    KP: "Nordkorea",
    MP: "Nördliche Marianen",
    NO: "Norwegen",
    OM: "Oman",
    PK: "Pakistan",
    PS: "Palästina",
    PA: "Panama",
    PG: "Papua-Neuguinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippinen",
    PL: "Polen",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Katar",
    CG: "Republik Kongo",
    RE: "Réunion",
    RO: "Rumänien",
    RU: "Russland",
    RW: "Ruanda",
    KN: "St. Kitts und Nevis",
    LC: "St. Lucia",
    MF: "St. Martin",
    PM: "St. Pierre und Miquelon",
    VC: "St. Vincent und die Grenadinen",
    WS: "Samoa",
    SM: "San Marino",
    ST: "São Tomé und Príncipe",
    SA: "Saudi-Arabien",
    SN: "Senegal",
    RS: "Serbien",
    SC: "Seychellen",
    SL: "Sierra Leone",
    SG: "Singapur",
    SK: "Slowakei",
    SI: "Slowenien",
    SB: "Salomonen",
    SO: "Somalia",
    ZA: "Südafrika",
    ES: "Spanien",
    SD: "Sudan",
    SR: "Suriname",
    SE: "Schweden",
    CH: "Schweiz",
    SY: "Syrien",
    TW: "Taiwan",
    TH: "Thailand",
    TT: "Trinidad und Tobago",
    TN: "Tunesien",
    TR: "Türkei",
    UA: "Ukraine",
    AE: "Vereinigte Arabische Emirate",
    GB: "Vereinigtes Königreich",
    US: "Vereinigte Staaten",
    UY: "Uruguay",
    UZ: "Usbekistan",
    VE: "Venezuela",
    VN: "Vietnam",
    YE: "Jemen",
    ZM: "Sambia",
    ZW: "Simbabwe",
  };
  return translations[value] || value;
}

function translateToFrench(value) {
  const translations = {
    AF: "Afghanistan",
    AL: "Albanie",
    DZ: "Algérie",
    AS: "Samoa américaines",
    AD: "Andorre",
    AO: "Angola",
    AI: "Anguilla",
    AR: "Argentine",
    AM: "Arménie",
    AW: "Aruba",
    AU: "Australie",
    AT: "Autriche",
    AZ: "Azerbaïdjan",
    BS: "Bahamas",
    BH: "Bahreïn",
    BD: "Bangladesh",
    BB: "Barbade",
    BY: "Biélorussie",
    BE: "Belgique",
    BZ: "Belize",
    BJ: "Bénin",
    BM: "Bermudes",
    BT: "Bhoutan",
    BO: "Bolivie",
    BA: "Bosnie-Herzégovine",
    BW: "Botswana",
    BR: "Brésil",
    VG: "Îles Vierges britanniques",
    BN: "Brunei",
    BG: "Bulgarie",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodge",
    CM: "Cameroun",
    CA: "Canada",
    CV: "Cap-Vert",
    KY: "Îles Caïmans",
    CF: "République centrafricaine",
    TD: "Tchad",
    CL: "Chili",
    CN: "Chine",
    CO: "Colombie",
    KM: "Comores",
    CK: "Îles Cook",
    CR: "Costa Rica",
    HR: "Croatie",
    CU: "Cuba",
    CW: "Curaçao",
    CY: "Chypre",
    CZ: "République tchèque",
    CD: "République démocratique du Congo",
    DK: "Danemark",
    DJ: "Djibouti",
    DM: "Dominique",
    DO: "République dominicaine",
    EC: "Équateur",
    EG: "Égypte",
    SV: "El Salvador",
    ER: "Érythrée",
    EE: "Estonie",
    ET: "Éthiopie",
    FO: "Îles Féroé",
    FJ: "Fidji",
    FI: "Finlande",
    FR: "France",
    PF: "Polynésie française",
    GA: "Gabon",
    GM: "Gambie",
    GE: "Géorgie",
    DE: "Allemagne",
    GH: "Ghana",
    GR: "Grèce",
    GL: "Groenland",
    GD: "Grenade",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernesey",
    GN: "Guinée",
    GW: "Guinée-Bissau",
    GY: "Guyana",
    HT: "Haïti",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hongrie",
    IS: "Islande",
    IN: "Inde",
    ID: "Indonésie",
    IR: "Iran",
    IQ: "Irak",
    IE: "Irlande",
    IM: "Île de Man",
    IL: "Israël",
    IT: "Italie",
    CI: "Côte d'Ivoire",
    JM: "Jamaïque",
    JP: "Japon",
    JE: "Jersey",
    JO: "Jordanie",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    XK: "Kosovo",
    KW: "Koweït",
    KG: "Kirghizistan",
    LA: "Laos",
    LV: "Lettonie",
    LB: "Liban",
    LS: "Lesotho",
    LR: "Libéria",
    LY: "Libye",
    LI: "Liechtenstein",
    LT: "Lituanie",
    LU: "Luxembourg",
    MO: "Macao",
    MK: "Macédoine du Nord",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaisie",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malte",
    MH: "Îles Marshall",
    MR: "Mauritanie",
    MU: "Maurice",
    YT: "Mayotte",
    MX: "Mexique",
    FM: "Micronésie",
    MD: "Moldavie",
    MC: "Monaco",
    MN: "Mongolie",
    ME: "Monténégro",
    MA: "Maroc",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibie",
    NP: "Népal",
    NL: "Pays-Bas",
    AN: "Antilles néerlandaises",
    NC: "Nouvelle-Calédonie",
    NZ: "Nouvelle-Zélande",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigéria",
    KP: "Corée du Nord",
    MP: "Îles Mariannes du Nord",
    NO: "Norvège",
    OM: "Oman",
    PK: "Pakistan",
    PS: "Palestine",
    PA: "Panama",
    PG: "Papouasie-Nouvelle-Guinée",
    PY: "Paraguay",
    PE: "Pérou",
    PH: "Philippines",
    PL: "Pologne",
    PT: "Portugal",
    PR: "Porto Rico",
    QA: "Qatar",
    CG: "République du Congo",
    RE: "La Réunion",
    RO: "Roumanie",
    RU: "Russie",
    RW: "Rwanda",
    KN: "Saint-Christophe-et-Niévès",
    LC: "Sainte-Lucie",
    MF: "Saint-Martin",
    PM: "Saint-Pierre-et-Miquelon",
    VC: "Saint-Vincent-et-les Grenadines",
    WS: "Samoa",
    SM: "Saint-Marin",
    ST: "Sao Tomé-et-Principe",
    SA: "Arabie Saoudite",
    SN: "Sénégal",
    RS: "Serbie",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapour",
    SK: "Slovaquie",
    SI: "Slovénie",
    SB: "Îles Salomon",
    SO: "Somalie",
    ZA: "Afrique du Sud",
    ES: "Espagne",
    SD: "Soudan",
    SR: "Suriname",
    SE: "Suède",
    CH: "Suisse",
    SY: "Syrie",
    TW: "Taïwan",
    TH: "Thaïlande",
    TT: "Trinité-et-Tobago",
    TN: "Tunisie",
    TR: "Turquie",
    UA: "Ukraine",
    AE: "Émirats arabes unis",
    GB: "Royaume-Uni",
    US: "États-Unis",
    UY: "Uruguay",
    UZ: "Ouzbékistan",
    VE: "Venezuela",
    VN: "Viêt Nam",
    YE: "Yémen",
    ZM: "Zambie",
    ZW: "Zimbabwe",
  };

  return translations[value] || value;
}

function translateToSpanish(value) {
  const translations = {
    AF: "Afganistán",
    AL: "Albania",
    DZ: "Argelia",
    AS: "Samoa Americana",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguila",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaiyán",
    BS: "Bahamas",
    BH: "Baréin",
    BD: "Bangladés",
    BB: "Barbados",
    BY: "Bielorrusia",
    BE: "Bélgica",
    BZ: "Belice",
    BJ: "Benín",
    BM: "Bermudas",
    BT: "Bután",
    BO: "Bolivia",
    BA: "Bosnia y Herzegovina",
    BW: "Botsuana",
    BR: "Brasil",
    VG: "Islas Vírgenes Británicas",
    BN: "Brunéi",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Camboya",
    CM: "Camerún",
    CA: "Canadá",
    CV: "Cabo Verde",
    KY: "Islas Caimán",
    CF: "República Centroafricana",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CO: "Colombia",
    KM: "Comoras",
    CK: "Islas Cook",
    CR: "Costa Rica",
    HR: "Croacia",
    CU: "Cuba",
    CW: "Curazao",
    CY: "Chipre",
    CZ: "Chequia",
    CD: "República Democrática del Congo",
    DK: "Dinamarca",
    DJ: "Yibuti",
    DM: "Dominica",
    DO: "República Dominicana",
    EC: "Ecuador",
    EG: "Egipto",
    SV: "El Salvador",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Etiopía",
    FO: "Islas Feroe",
    FJ: "Fiyi",
    FI: "Finlandia",
    FR: "Francia",
    PF: "Polinesia Francesa",
    GA: "Gabón",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Alemania",
    GH: "Ghana",
    GR: "Grecia",
    GL: "Groenlandia",
    GD: "Granada",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bisáu",
    GY: "Guyana",
    HT: "Haití",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungría",
    IS: "Islandia",
    IN: "India",
    ID: "Indonesia",
    IR: "Irán",
    IQ: "Irak",
    IE: "Irlanda",
    IM: "Isla de Man",
    IL: "Israel",
    IT: "Italia",
    CI: "Costa de Marfil",
    JM: "Jamaica",
    JP: "Japón",
    JE: "Jersey",
    JO: "Jordania",
    KZ: "Kazajistán",
    KE: "Kenia",
    KI: "Kiribati",
    XK: "Kosovo",
    KW: "Kuwait",
    KG: "Kirguistán",
    LA: "Laos",
    LV: "Letonia",
    LB: "Líbano",
    LS: "Lesoto",
    LR: "Liberia",
    LY: "Libia",
    LI: "Liechtenstein",
    LT: "Lituania",
    LU: "Luxemburgo",
    MO: "Macao",
    MK: "Macedonia del Norte",
    MG: "Madagascar",
    MW: "Malaui",
    MY: "Malasia",
    MV: "Maldivas",
    ML: "Malí",
    MT: "Malta",
    MH: "Islas Marshall",
    MR: "Mauritania",
    MU: "Mauricio",
    YT: "Mayotte",
    MX: "México",
    FM: "Micronesia",
    MD: "Moldavia",
    MC: "Mónaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MA: "Marruecos",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NP: "Nepal",
    NL: "Países Bajos",
    AN: "Antillas Neerlandesas",
    NC: "Nueva Caledonia",
    NZ: "Nueva Zelanda",
    NI: "Nicaragua",
    NE: "Níger",
    NG: "Nigeria",
    KP: "Corea del Norte",
    MP: "Islas Marianas del Norte",
    NO: "Noruega",
    OM: "Omán",
    PK: "Pakistán",
    PS: "Palestina",
    PA: "Panamá",
    PG: "Papúa Nueva Guinea",
    PY: "Paraguay",
    PE: "Perú",
    PH: "Filipinas",
    PL: "Polonia",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Catar",
    CG: "República del Congo",
    RE: "Reunión",
    RO: "Rumanía",
    RU: "Rusia",
    RW: "Ruanda",
    KN: "San Cristóbal y Nieves",
    LC: "Santa Lucía",
    MF: "San Martín",
    PM: "San Pedro y Miquelón",
    VC: "San Vicente y las Granadinas",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Santo Tomé y Príncipe",
    SA: "Arabia Saudita",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leona",
    SG: "Singapur",
    SK: "Eslovaquia",
    SI: "Eslovenia",
    SB: "Islas Salomón",
    SO: "Somalia",
    ZA: "Sudáfrica",
    ES: "España",
    SD: "Sudán",
    SR: "Surinam",
    SE: "Suecia",
    CH: "Suiza",
    SY: "Siria",
    TW: "Taiwán",
    TH: "Tailandia",
    TT: "Trinidad y Tobago",
    TN: "Túnez",
    TR: "Turquía",
    UA: "Ucrania",
    AE: "Emiratos Árabes Unidos",
    GB: "Reino Unido",
    US: "Estados Unidos",
    UY: "Uruguay",
    UZ: "Uzbekistán",
    VE: "Venezuela",
    VN: "Vietnam",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabue",
  };

  return translations[value] || value; // Return original if no translation is available
}

function translateToItalian(value) {
  const translations = {
    AF: "Afghanistan",
    AL: "Albania",
    DZ: "Algeria",
    AS: "Samoa Americane",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaigian",
    BS: "Bahamas",
    BH: "Bahrein",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Bielorussia",
    BE: "Belgio",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia",
    BA: "Bosnia ed Erzegovina",
    BW: "Botswana",
    BR: "Brasile",
    VG: "Isole Vergini Britanniche",
    BN: "Brunei",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambogia",
    CM: "Camerun",
    CA: "Canada",
    CV: "Capo Verde",
    KY: "Isole Cayman",
    CF: "Repubblica Centrafricana",
    TD: "Ciad",
    CL: "Cile",
    CN: "Cina",
    CO: "Colombia",
    KM: "Comore",
    CK: "Isole Cook",
    CR: "Costa Rica",
    HR: "Croazia",
    CU: "Cuba",
    CW: "Curaçao",
    CY: "Cipro",
    CZ: "Repubblica Ceca",
    CD: "Repubblica Democratica del Congo",
    DK: "Danimarca",
    DJ: "Gibuti",
    DM: "Dominica",
    DO: "Repubblica Dominicana",
    EC: "Ecuador",
    EG: "Egitto",
    SV: "El Salvador",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Etiopia",
    FO: "Isole Faroe",
    FJ: "Figi",
    FI: "Finlandia",
    FR: "Francia",
    PF: "Polinesia Francese",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germania",
    GH: "Ghana",
    GR: "Grecia",
    GL: "Groenlandia",
    GD: "Grenada",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Ungheria",
    IS: "Islanda",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran",
    IQ: "Iraq",
    IE: "Irlanda",
    IM: "Isola di Man",
    IL: "Israele",
    IT: "Italia",
    CI: "Costa d'Avorio",
    JM: "Giamaica",
    JP: "Giappone",
    JE: "Jersey",
    JO: "Giordania",
    KZ: "Kazakistan",
    KE: "Kenya",
    KI: "Kiribati",
    XK: "Kosovo",
    KW: "Kuwait",
    KG: "Kirghizistan",
    LA: "Laos",
    LV: "Lettonia",
    LB: "Libano",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libia",
    LI: "Liechtenstein",
    LT: "Lituania",
    LU: "Lussemburgo",
    MO: "Macao",
    MK: "Macedonia del Nord",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malesia",
    MV: "Maldive",
    ML: "Mali",
    MT: "Malta",
    MH: "Isole Marshall",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Messico",
    FM: "Micronesia",
    MD: "Moldavia",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MA: "Marocco",
    MZ: "Mozambico",
    MM: "Myanmar",
    NA: "Namibia",
    NP: "Nepal",
    NL: "Paesi Bassi",
    AN: "Antille Olandesi",
    NC: "Nuova Caledonia",
    NZ: "Nuova Zelanda",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    KP: "Corea del Nord",
    MP: "Isole Marianne Settentrionali",
    NO: "Norvegia",
    OM: "Oman",
    PK: "Pakistan",
    PS: "Palestina",
    PA: "Panama",
    PG: "Papua Nuova Guinea",
    PY: "Paraguay",
    PE: "Perù",
    PH: "Filippine",
    PL: "Polonia",
    PT: "Portogallo",
    PR: "Porto Rico",
    QA: "Qatar",
    CG: "Repubblica del Congo",
    RE: "Riunione",
    RO: "Romania",
    RU: "Russia",
    RW: "Ruanda",
    KN: "Saint Kitts e Nevis",
    LC: "Saint Lucia",
    MF: "Saint Martin",
    PM: "Saint-Pierre e Miquelon",
    VC: "Saint Vincent e Grenadine",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tomé e Príncipe",
    SA: "Arabia Saudita",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SK: "Slovacchia",
    SI: "Slovenia",
    SB: "Isole Salomone",
    SO: "Somalia",
    ZA: "Sudafrica",
    ES: "Spagna",
    SD: "Sudan",
    SR: "Suriname",
    SE: "Svezia",
    CH: "Svizzera",
    SY: "Siria",
    TW: "Taiwan",
    TH: "Thailandia",
    TT: "Trinidad e Tobago",
    TN: "Tunisia",
    TR: "Turchia",
    UA: "Ucraina",
    AE: "Emirati Arabi Uniti",
    GB: "Regno Unito",
    US: "Stati Uniti",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VE: "Venezuela",
    VN: "Vietnam",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
  };

  return translations[value] || value; // Return original if no translation is available
}

function translateToPortuguese(value) {
  const translations = {
    AF: "Afeganistão",
    AL: "Albânia",
    DZ: "Argélia",
    AS: "Samoa Americana",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguila",
    AR: "Argentina",
    AM: "Armênia",
    AW: "Aruba",
    AU: "Austrália",
    AT: "Áustria",
    AZ: "Azerbaijão",
    BS: "Bahamas",
    BH: "Bahrein",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Bielorrússia",
    BE: "Bélgica",
    BZ: "Belize",
    BJ: "Benim",
    BM: "Bermudas",
    BT: "Butão",
    BO: "Bolívia",
    BA: "Bósnia e Herzegovina",
    BW: "Botsuana",
    BR: "Brasil",
    VG: "Ilhas Virgens Britânicas",
    BN: "Brunei",
    BG: "Bulgária",
    BF: "Burquina Faso",
    BI: "Burundi",
    KH: "Camboja",
    CM: "Camarões",
    CA: "Canadá",
    CV: "Cabo Verde",
    KY: "Ilhas Cayman",
    CF: "República Centro-Africana",
    TD: "Chade",
    CL: "Chile",
    CN: "China",
    CO: "Colômbia",
    KM: "Comores",
    CK: "Ilhas Cook",
    CR: "Costa Rica",
    HR: "Croácia",
    CU: "Cuba",
    CW: "Curaçau",
    CY: "Chipre",
    CZ: "República Tcheca",
    CD: "República Democrática do Congo",
    DK: "Dinamarca",
    DJ: "Djibuti",
    DM: "Dominica",
    DO: "República Dominicana",
    EC: "Equador",
    EG: "Egito",
    SV: "El Salvador",
    ER: "Eritreia",
    EE: "Estônia",
    ET: "Etiópia",
    FO: "Ilhas Faroé",
    FJ: "Fiji",
    FI: "Finlândia",
    FR: "França",
    PF: "Polinésia Francesa",
    GA: "Gabão",
    GM: "Gâmbia",
    GE: "Geórgia",
    DE: "Alemanha",
    GH: "Gana",
    GR: "Grécia",
    GL: "Groenlândia",
    GD: "Granada",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guiné",
    GW: "Guiné-Bissau",
    GY: "Guiana",
    HT: "Haiti",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungria",
    IS: "Islândia",
    IN: "Índia",
    ID: "Indonésia",
    IR: "Irã",
    IQ: "Iraque",
    IE: "Irlanda",
    IM: "Ilha de Man",
    IL: "Israel",
    IT: "Itália",
    CI: "Costa do Marfim",
    JM: "Jamaica",
    JP: "Japão",
    JE: "Jersey",
    JO: "Jordânia",
    KZ: "Cazaquistão",
    KE: "Quênia",
    KI: "Quiribati",
    XK: "Kosovo",
    KW: "Kuwait",
    KG: "Quirguistão",
    LA: "Laos",
    LV: "Letônia",
    LB: "Líbano",
    LS: "Lesoto",
    LR: "Libéria",
    LY: "Líbia",
    LI: "Liechtenstein",
    LT: "Lituânia",
    LU: "Luxemburgo",
    MO: "Macau",
    MK: "Macedônia do Norte",
    MG: "Madagáscar",
    MW: "Malaui",
    MY: "Malásia",
    MV: "Maldivas",
    ML: "Mali",
    MT: "Malta",
    MH: "Ilhas Marshall",
    MR: "Mauritânia",
    MU: "Maurício",
    YT: "Mayotte",
    MX: "México",
    FM: "Micronésia",
    MD: "Moldávia",
    MC: "Mônaco",
    MN: "Mongólia",
    ME: "Montenegro",
    MA: "Marrocos",
    MZ: "Moçambique",
    MM: "Mianmar",
    NA: "Namíbia",
    NP: "Nepal",
    NL: "Países Baixos",
    AN: "Antilhas Holandesas",
    NC: "Nova Caledônia",
    NZ: "Nova Zelândia",
    NI: "Nicarágua",
    NE: "Níger",
    NG: "Nigéria",
    KP: "Coreia do Norte",
    MP: "Ilhas Marianas do Norte",
    NO: "Noruega",
    OM: "Omã",
    PK: "Paquistão",
    PS: "Palestina",
    PA: "Panamá",
    PG: "Papua Nova Guiné",
    PY: "Paraguai",
    PE: "Peru",
    PH: "Filipinas",
    PL: "Polônia",
    PT: "Portugal",
    PR: "Porto Rico",
    QA: "Catar",
    CG: "República do Congo",
    RE: "Reunião",
    RO: "Romênia",
    RU: "Rússia",
    RW: "Ruanda",
    KN: "São Cristóvão e Névis",
    LC: "Santa Lúcia",
    MF: "São Martinho",
    PM: "São Pedro e Miquelão",
    VC: "São Vicente e Granadinas",
    WS: "Samoa",
    SM: "San Marino",
    ST: "São Tomé e Príncipe",
    SA: "Arábia Saudita",
    SN: "Senegal",
    RS: "Sérvia",
    SC: "Seicheles",
    SL: "Serra Leoa",
    SG: "Cingapura",
    SK: "Eslováquia",
    SI: "Eslovênia",
    SB: "Ilhas Salomão",
    SO: "Somália",
    ZA: "África do Sul",
    ES: "Espanha",
    SD: "Sudão",
    SR: "Suriname",
    SE: "Suécia",
    CH: "Suíça",
    SY: "Síria",
    TW: "Taiwan",
    TH: "Tailândia",
    TT: "Trinidad e Tobago",
    TN: "Tunísia",
    TR: "Turquia",
    UA: "Ucrânia",
    AE: "Emirados Árabes Unidos",
    GB: "Reino Unido",
    US: "Estados Unidos",
    UY: "Uruguai",
    UZ: "Uzbequistão",
    VE: "Venezuela",
  };
}
