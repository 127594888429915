import React, { useEffect, useState } from "react";
import { COUNTRIES, translatedCountries } from "./lib/countries";
import {
  DISTANCES,
  ALTITUDES,
  DEFAULT_FILTERS,
  MIN_UPHILL,
  MIN_DISTANCE,
  MAX_UPHILL,
  MAX_DISTANCE,
  DISCIPLINES,
} from "./constants/constants";

const ActiveFilters = ({ text, data, setData, triggerSubmit, locale }) => {
  const [activeFilters, setActiveFilters] = useState();

  useEffect(() => {
    if (data) {
      prepareData();
    }
  }, [data]);

  function prepareData() {
    const keysToKeep = ["country", "title", "event_partners_only", "course_discipline_ids"];
    const newObj = { ...data };
    for (const key in newObj) {
      if (key.includes("_min") || key.includes("_max") || key.includes("submit")) {
        delete newObj[key];
      } else if (!keysToKeep.includes(key) && Number(newObj[key]) === 0) {
        delete newObj[key];
      } else if (key === "partner_events_only" && !!!newObj[key]) {
        delete newObj[key];
      } else if (!!!newObj[key]) {
        delete newObj[key];
      }
    }
    const filters = setTags(newObj);
    setActiveFilters(filters);
  }

  function setTags(data) {
    const today = new Date().toISOString().split("T")[0];
    const start_date = data.start_date_from && new Date(data.start_date_from).toISOString().split("T")[0];
    const newData = { ...data };
    const distanceFrom = Number(newData["course_distance_from"]);
    const distanceTo = Number(newData["course_distance_to"]);
    const uphillTo = Number(newData["course_uphill_to"]);
    const uphillFrom = Number(newData["course_uphill_from"]);
    const newArray = [];
    for (const key in DISTANCES) {
      if (DISTANCES[key].min >= distanceFrom && DISTANCES[key].max <= distanceTo) {
        newArray.push({ distance: key });
      }
    }
    for (const key in ALTITUDES) {
      if (ALTITUDES[key].min >= uphillFrom && ALTITUDES[key].max <= uphillTo) {
        newArray.push({ uphill: key });
      }
    }
    if (newData.country) {
      const title = translatedCountries.find((country) => country.value === newData.country)[locale];
      newArray.push({ country: title });
    }
    if (newData.start_date_from && start_date !== today) {
      newArray.push({ start_date_from: newData.start_date_from });
    }
    if (newData.partner_events_only) {
      newArray.push({ partner_events_only: text.partner_event });
    }
    if (newData.title) {
      newArray.push({ title: newData.title });
    }
    if (newData.course_discipline_ids) {
      const activeIds = newData.course_discipline_ids.split(",").map(Number);
      if (activeIds.length > 0) {
        activeIds.map((id) => {
          let discipline = Object.entries(DISCIPLINES).find((discipline) => discipline[1] === id);
          newArray.push({ course_discipline_ids: discipline[0] });
        });
      }
    }
    return newArray;
  }

  function resetUphill(key, value) {
    const newRange = calculateNewRange(key, value, ALTITUDES);
    const noRange = newRange.min === newRange.max;
    return {
      ["course_uphill_from"]: noRange ? MIN_UPHILL : newRange.min,
      ["course_uphill_to"]: noRange ? MAX_UPHILL : newRange.max,
    };
  }

  function resetDistance(key, value) {
    const newRange = calculateNewRange(key, value, DISTANCES);
    const noRange = newRange.min === newRange.max;
    return {
      ["course_distance_from"]: noRange ? MIN_DISTANCE : newRange.min,
      ["course_distance_to"]: noRange ? MAX_DISTANCE : newRange.max,
    };
  }

  function resetDiscipline(key, value, newKeys) {
    const id = DISCIPLINES[value];
    const filteredData = activeFilters.filter(
      (item) => item.hasOwnProperty("course_discipline_ids") && item["course_discipline_ids"] !== value
    );
    const resultString = filteredData.map((item) => DISCIPLINES[Object.values(item)[0]]).join(",");
    return resultString;
  }

  function calculateNewRange(key, value, range) {
    let newRange = { min: 0, max: 0 };
    // only one filter?
    const filterLength = activeFilters.filter((af) => af[key]).length;
    if (filterLength == 1) {
      return newRange;
    }
    const minActive = activeFilters.filter((flt) => flt[key]).map((flt) => range[flt[key]].min);
    const maxActive = activeFilters.filter((flt) => flt[key]).map((flt) => range[flt[key]].max);
    if (range[value].min === Math.min(...minActive)) {
      newRange.min = range[value].max;
      newRange.max = Math.max(...maxActive);
      // value tenKm is the largest of the active filters
    } else if (range[value].max === Math.max(...maxActive)) {
      newRange.min = Math.min(...minActive);
      newRange.max = range[value].min;
      // value tenkm is in the middle
    } else {
      newRange.min = Math.min(...minActive);
      newRange.max = range[value].max;
    }
    return newRange;
  }

  function handleClick(key, value) {
    let newKeys = { ...data };
    if (key === "distance") {
      newKeys = resetDistance(key, value);
    } else if (key === "uphill") {
      newKeys = resetUphill(key, value);
    } else if (key === "course_discipline_ids") {
      newKeys = {
        [key]: resetDiscipline(key, value),
      };
    } else {
      newKeys = {
        [key]: resetValue(key),
      };
    }
    const newData = {
      ...data,
      ...newKeys,
      submit: true,
    };
    setData(newData);
    triggerSubmit(true);
  }

  function resetValue(key) {
    return DEFAULT_FILTERS[key];
  }

  function handleReset() {
    setData({ submit: true });
    triggerSubmit(true);
  }

  function setBackgroundColor(key, value) {
    if (key === "distance" || key === "uphill") {
      const RANGE = key === "distance" ? DISTANCES : ALTITUDES;
      return RANGE[value].color;
    } else {
      return "#212529";
    }
  }

  return (
    <div
      className="pt-2 d-flex justify-content-start ps-lg-4 ps-1 bg-light-blue pb-4"
      style={{
        minHeight: 50,
        boxShadow: "0px 20px 1rem -10px rgba(37, 42, 64, 0.07)",
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
      }}
    >
      {activeFilters && Object.keys(activeFilters).length > 0 && (
        <div className="gap-2 d-flex align-items-center flex-wrap">
          <div className="d-flex flex-align-items-center flex-wrap gap-2">
            {activeFilters.map((elem) => {
              const [[key, value]] = Object.entries(elem);

              return (
                <label
                  className="d-flex align-items-center gap-2 badge text-light py-2 pe-pointer"
                  onClick={() => handleClick(key, value)}
                  style={{ fontSize: 13, backgroundColor: setBackgroundColor(key, value) }}
                  key={value}
                >
                  {key === "distance" || key === "uphill" || key === "course_discipline_ids" ? text[value] : value}
                  <img src={"/assets/iconPark/_light/close.svg"} width={12} height={12} />
                </label>
              );
            })}
          </div>
          <div className="px-3 py-2 fw-light text-decoration-underline pe-pointer" onClick={() => handleReset()}>
            {text.reset_all}
          </div>
        </div>
      )}
    </div>
  );
};

export default ActiveFilters;
