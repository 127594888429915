import React, { useEffect, useState } from "react";
import { ALTITUDES, DISTANCES } from "./constants/constants";

const Pills = ({ text, param, data, setData }) => {
  const [activeFilters, setActiveFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    prepareData();
  }, []);

  const items = param === "distance" ? DISTANCES : ALTITUDES;
  const distanceFrom = Number(data["course_distance_from"]);
  const distanceTo = Number(data["course_distance_to"]);
  const uphillTo = Number(data["course_uphill_to"]);
  const uphillFrom = Number(data["course_uphill_from"]);

  function handleClick(item) {
    const keys = evaluateNewPill(item);
    const newData = param === "distance" ? setNewDistance(keys) : setNewUphill(keys);
    setData({ ...data, ...newData, submit: true });
  }

  function setNewDistance(keys) {
    const maxIndex = keys[1];
    const minIndex = keys[0];
    const min = Object.values(DISTANCES)[minIndex].min;
    const max = Object.values(DISTANCES)[maxIndex].max;
    return { course_distance_from: min, course_distance_to: max };
  }

  function setNewUphill(keys) {
    const maxIndex = keys[1];
    const minIndex = keys[0];
    const min = Object.values(ALTITUDES)[minIndex].min;
    const max = Object.values(ALTITUDES)[maxIndex].max;
    return { course_uphill_from: min, course_uphill_to: max };
  }

  function evaluateNewPill(selectedPill) {
    let newRange = [];
    const keys = param === "distance" ? Object.keys(DISTANCES) : Object.keys(ALTITUDES);
    const selectedTitle = selectedPill.title;
    const selectedIndex = keys.indexOf(selectedTitle);
    if (activeFilters.length > 0 && activeFilters.length < keys.length) {
      const activeTitles = activeFilters.map((pill) => pill.title);
      const activeIndexes = activeTitles.map((title) => keys.indexOf(title));
      const minActiveIndex = Math.min(...activeIndexes);
      const maxActiveIndex = Math.max(...activeIndexes);

      if (activeFilters.length === 1 && activeTitles[0] == selectedTitle) {
        newRange = [0, keys.length - 1];
      } else if (activeFilters.length === 2 && activeFilters.find((_) => _.title == selectedTitle) !== undefined) {
        const remainingPill = activeFilters.find((f) => f.title !== selectedTitle);
        newRange = [keys.indexOf(remainingPill.title), keys.indexOf(remainingPill.title)];
      } else {
        const [startIndex, endIndex] =
          maxActiveIndex <= selectedIndex
            ? [minActiveIndex, selectedIndex]
            : minActiveIndex >= selectedIndex
            ? [selectedIndex, maxActiveIndex]
            : [minActiveIndex, selectedIndex];
        newRange = [startIndex, endIndex];
      }
    } else {
      newRange = [selectedIndex, selectedIndex];
    }
    return newRange;
  }

  function prepareData() {
    const res = [];

    const source = param === "distance" ? DISTANCES : ALTITUDES;
    const from = param === "distance" ? distanceFrom : uphillFrom;
    const to = param === "distance" ? distanceTo : uphillTo;

    for (const key in source) {
      if (source[key].min >= from && source[key].max <= to) {
        res.push(source[key]);
      }
    }
    setActiveFilters(res);
    setIsLoading(false);
  }

  function isPillActive(item) {
    return activeFilters.length > 0 && activeFilters.some((active) => active.title === item.title);
  }

  return (
    <div className="d-flex flex-row gap-2 flex-wrap">
      {!isLoading &&
        Object.values(items).map((item) => {
          return (
            <div
              className="badge rounded d-flex justify-content-between gap-3 fw-light pe-pointer"
              key={item.title}
              style={{
                backgroundColor: activeFilters && isPillActive(item) ? item.color : `${item.color}11`,
                borderWidth: 2,
                borderStyle: "solid",
                borderColor: item.color,
                fontSize: 14,
                color: activeFilters && isPillActive(item) ? "white" : "black",
              }}
              onClick={() => handleClick(item)}
            >
              {text[item.title] ? text[item.title] : item.title}
            </div>
          );
        })}
    </div>
  );
};

export default Pills;
