import React, { useEffect, useState } from "react";
import { DISCIPLINES } from "./constants/constants";

const Pills = ({ text, param, data, setData }) => {
  const [activeFilters, setActiveFilters] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    prepareData();
  }, []);

  function handleClick(id: number) {
    const keys = evaluateNewPill(id);
    const newData = { [param]: keys };
    setData({ ...data, ...newData, submit: true });
  }

  function evaluateNewPill(selectedId: number) {
    let newIds: number[] = [];
    const hasId = activeFilters.find((activeId: number) => activeId === selectedId) !== undefined;
    if (hasId) {
      newIds = activeFilters.filter((id: number) => id !== selectedId);
    } else {
      newIds = [...activeFilters, selectedId];
    }
    return newIds.join(",");
  }

  function prepareData() {
    if (data[param]) {
      const activeIds = data[param].split(",").map(Number);
      if (activeIds) {
        setActiveFilters(activeIds);
      }
    }
    setIsLoading(false);
  }

  function isPillActive(id: number) {
    return Object.values(activeFilters).find((sport_id) => sport_id === id) !== undefined;
  }

  function setDiscipline(id: number) {
    const disciplineKey = Object.keys(DISCIPLINES).find((key) => DISCIPLINES[key] === id);
    return text[disciplineKey];
  }

  return (
    <div className="d-flex flex-row gap-2 flex-wrap">
      {!isLoading &&
        Object.values(DISCIPLINES).map((id: number) => {
          return (
            <div
              className={`${
                isPillActive(id) ? "text-light" : "text-dark"
              } badge rounded d-flex justify-content-between gap-3 fw-light pe-pointer`}
              key={id}
              style={{
                backgroundColor: isPillActive(id) ? "black" : "#f4f5f9",
                borderWidth: 2,
                borderStyle: "solid",
                fontSize: 14,
              }}
              onClick={() => handleClick(id)}
            >
              {setDiscipline(id)}
            </div>
          );
        })}
    </div>
  );
};

export default Pills;
