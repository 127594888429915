import React, { useState, useEffect } from "react";
import AutocompleteTitle from "./AutocompleteTitle";
import FiltersMenu from "./FiltersMenu";
import FilterElements from "./FilterElements";
import ActiveFilters from "./ActiveFilters";
import { DEFAULT_FILTERS } from "./constants/constants";

type ShowFilter = "title" | "date" | "country" | "distance" | "uphill" | "partner_event" | "discipline";

const Filters = ({ filters, scope, unit, translations, locale, user_country }) => {
  const [data, setData] = useState();
  const [showFilter, toggleFilter] = useState<ShowFilter>();
  const [submit, triggerSubmit] = useState<boolean>(false);
  const [text, setText] = useState();
  const [submitEnabled, setSubmitEnabled] = useState();

  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    if (data && data.submit) {
      setSubmitEnabled(true);
    }
  }, [data]);

  useEffect(() => {
    if (data && submit) {
      handleSubmit();
    }
  }, [submit]);

  function handleData() {
    const props = { ...filters, ...scope };
    setData({ ...props, submit: false });
    setText(translations);
  }

  function filterKeys() {
    const keysToKeep = [
      "start_date_from",
      "title",
      "country",
      "course_distance_from",
      "course_distance_to",
      "course_uphill_from",
      "course_uphill_to",
      "partner_events_only",
      "race_sport_type_ids",
      "course_discipline_ids",
    ];
    const res = Object.keys(data).filter(
      (item) => keysToKeep.includes(item) && !!data[item] && data[item] !== DEFAULT_FILTERS[item]
    );
    return res;
  }

  function buildUrl(keys) {
    let url = "/events";
    if (keys.length > 0) {
      url += `?q[title]=`;
      keys.map((key) => {
        let name = `q[${key}]`;
        key === "title" ? (url += data[key]) : (url += `&${name}=${data[key]}`);
      });
      if (!keys.includes("start_date_from")) {
        url += `&q[start_date_from]=${DEFAULT_FILTERS["start_date_from"]}`;
      }
      url += "&q[verified_only]=false&commit=Filter";
    }
    return url;
  }

  function handleSubmit() {
    const keys = filterKeys();
    const url = buildUrl(keys);
    toggleFilter("");
    window.location.href = url;
  }

  return (
    <>
      {data && (
        <div className="d-flex flex-column">
          <div className="d-flex flex-column">
            <div className="d-flex flex-row">
              <div className="d-flex flex-column flex-grow-1">
                <div className="d-flex flex-column flex-grow-1">
                  <div
                    className="d-flex flex-row pb-2 bg-light-blue"
                    style={{ borderTopLeftRadius: 20, borderTopRightRadius: 20 }}
                  >
                    <AutocompleteTitle
                      text={text}
                      triggerSubmit={(a) => triggerSubmit(a)}
                      data={data}
                      setData={(a) => setData(a)}
                    />
                  </div>
                  <FiltersMenu
                    text={text}
                    showFilter={showFilter}
                    toggleFilter={(e) => toggleFilter(e)}
                    data={data}
                    setData={(a) => setData(a)}
                    submitEnabled={submitEnabled}
                    triggerSubmit={(a) => triggerSubmit(a)}
                  />
                </div>
              </div>
            </div>
            <FilterElements
              text={text}
              showFilter={showFilter}
              toggleFilter={(a) => toggleFilter(a)}
              data={data}
              setData={(a) => setData(a)}
              triggerSubmit={(a) => triggerSubmit(a)}
              submitEnabled={submitEnabled}
              locale={locale}
              user_country={user_country}
            />
          </div>
          <ActiveFilters
            locale={locale}
            text={text}
            data={data}
            setData={(a) => setData(a)}
            triggerSubmit={(a) => triggerSubmit(a)}
          />
        </div>
      )}
    </>
  );
};

export default Filters;
