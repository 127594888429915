import React from "react";

const FiltersMenu = ({ showFilter, toggleFilter, text, data, setData, submitEnabled, triggerSubmit }) => {
  const pillClasses =
    "nav-item px-3 py-2 border rounded-pill bg-light d-flex gap-2 align-items-center pe-auto pe-pointer";

  function handleClick(e) {
    toggleFilter(e);
  }

  return (
    <div className="d-flex flex-column pt-4 w-100 bg-light-blue">
      <label className="ps-lg-6 ps-2 pe-2 mb-2">{text.filter_by}:</label>
      <div className="ps-lg-4 ps-0 d-flex fw-light flex-row align-items-center justify-content-start flex-wrap">
        <div className="d-flex gap-2 flex-wrap">
          <div className={pillClasses} onClick={() => handleClick("all")}>
            <img src={"/assets/iconPark/equalizer.svg"} width={14} height={14} />
            <span>{text.all_filters}</span>
            <img src={"/assets/iconPark/down.svg"} width={14} height={14} />
          </div>
          <div
            className={`${pillClasses} ${showFilter === "date" ? "border-dark" : ""}`}
            onClick={() => handleClick("date")}
          >
            <img src={"/assets/iconPark/calendar-thirty-two.svg"} width={14} height={14} />
            {text.date}
            <img src={"/assets/iconPark/down.svg"} width={14} height={14} />
          </div>
          <div
            className={`${pillClasses} ${showFilter === "country" ? "border-dark" : ""}`}
            onClick={() => handleClick("country")}
          >
            <img src={"/assets/iconPark/local-two.svg"} width={14} height={14} />
            {text.country}
            <img src={"/assets/iconPark/down.svg"} width={14} height={14} />
          </div>
          <div
            className={`${pillClasses} ${showFilter === "distance" ? "border-dark" : ""}`}
            onClick={() => handleClick("distance")}
          >
            <img src={"/assets/iconPark/assembly-line.svg"} width={14} height={14} />
            {text.distance}
            <img src={"/assets/iconPark/down.svg"} width={14} height={14} />
          </div>

          <div
            className={`${pillClasses} ${showFilter === "uphill" ? "border-dark" : ""}`}
            onClick={() => handleClick("uphill")}
          >
            <img src={"/assets/iconPark/arrow-right-up.svg"} width={14} height={14} />
            {text.uphill}
            <img src={"/assets/iconPark/down.svg"} width={14} height={14} />
          </div>
          <div
            className={`${pillClasses} ${showFilter === "course_discipline_ids" ? "border-dark" : ""}`}
            onClick={() => handleClick("course_discipline_ids")}
          >
            <img src={"/assets/iconPark/foot.svg"} width={14} height={14} />
            {text.discipline}
            <img src={"/assets/iconPark/down.svg"} width={14} height={14} />
          </div>
          <div
            className={`${pillClasses} ${showFilter === "partner" ? "border-dark" : ""}`}
            onClick={() => handleClick("partner")}
          >
            <div className="form-check d-flex align-items-center gap-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexCheckDefault"
                defaultChecked={data.partner_events_only}
                onChange={(e) => {
                  setData({ ...data, partner_events_only: e.target.checked, submit: true });
                }}
              />
              <label className="form-check-label">{text.partner_event}</label>
            </div>
          </div>
          {submitEnabled && (
            <button className="btn bg-dark text-light rounded-circle" onClick={() => triggerSubmit(true)}>
              <img
                style={{ transform: "rotate(-45deg)", marginTop: -2 }}
                src={"/assets/iconPark/_light/arrow-right-down.svg"}
                width={20}
                height={20}
              />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default FiltersMenu;
