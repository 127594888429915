'use strict';

import React from 'react';
import PropTypes from 'prop-types';

export default class AutocompleteTitleItem extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    handleItemClick: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired
  }

  render() {
    return (
      <li className={`fw-light py-2 ps-lg-3 ps-0 fs-3 ${this.props.index === 0 ? '' : 'border-bottom'}`} onClick={this.props.handleItemClick}>
        {this.props.name}
      </li>
    );
  }
}
